/* styles.css */
.administratTable {
  border-collapse: separate;
  border: 1px solid #ececec;
  padding: 0;
  width: 25px;
  }
  
  .administratTable th,
  .administratTable td {
    padding: 3px;
  }
  
  .administratTableCell {
    background-color: #fafafa;
    color: #9499a4;
    font-family: 'Akatab', sans-serif;
    font-size: -16px;
    font-weight: 500;
    height: 25px;
  }
  .administratCommon{
    margin: 10px;
      padding: 10px;
      width: min-content;
  }
  
  .administratTableEachCell {
    height: 18px;
  }
  
  .administratHeaderText {
    font-size: 13px;
  }
  
  .administratTableRow > * {
    border: none;
  }
  
  .tableRow {
    height: 8px;
  }
  
  .administratCustomIcon {
    font-size: 14px;
      color: #616366;
  }
  

  /* Style the scrollbar */
.scrollBar::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed for a smaller scrollbar */
  height: 6px; /* Adjust the height as needed for a smaller scrollbar */
}

/* Track */
.scrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollBar::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 10px; 
}

/* Handle on hover */
.scrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
