/* styles.css */
.landingTable {
  border-collapse: separate;
  border: 1px solid #ececec;
  padding: 0;
}

.landingTable th,
.landingTable td {
  padding: 4px;
}

.landingTableCell {
  background-color: #fafafa;
  color: #9499a4;
  font-family: "Akatab", sans-serif;
  font-size: -16px;
  font-weight: 500;
  height: 20px;
}

.landingTableEachCell {
  height: 18px;
}

.landingHeaderText {
  font-size: 13px;
}

.landingTableRow > * {
  border: none;
}

.tableRow {
  height: 8px;
}

.landingCustomIcon {
  font-size: 16px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  border: 1px solid transparent;
  box-shadow: 0 0 0 1000px white inset;
}
