/* styles.css */
.Partner {
  border-collapse: separate;
  border: 1px solid #ececec;
  padding: 0;
  width: 25px;
}

.Partner th,
.Partner td {
  padding: 3px;
}

.PartnerCell {
  background-color: #fafafa;
  color: #9499a4;
  font-family: "Akatab", sans-serif;
  font-size: -16px;
  font-weight: 500;
  height: 25px;
}

.PartnerCommon {
  margin: 10px;
  padding: 10px;
  width: min-content;
}

.PartnerEachCell {
  height: 18px;
}

.PartnerHeaderText {
  font-size: 13px;
}

.PartnerTableRow > * {
  border: none;
}

.tableRow {
  height: 8px;
}

.PartnerCustomIcon {
  font-size: 14px;
  color: #616366;
}

/* scroll Bar */
/* External CSS */

/* Style the scrollbar */
/* External CSS */

/* Style the scrollbar */
.scrollBar::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed for a smaller scrollbar */
  height: 6px; /* Adjust the height as needed for a smaller scrollbar */
}

/* Track */
.scrollBar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scrollBar::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 10px;
}

/* Handle on hover */
.scrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
