/* Styles for the form control wrapper */
.formControlWrapper {
    position: relative;
  }
  
  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  /* Styles for the label when it moves to the top */
  .formControlLabelRoleMoved {
    position: absolute;
    top: -8px;
    left: 14px;
    background-color: #fff;
    padding: 2px 4px;
    pointer-events: none;
    transition: top 0.2s, font-size 0.2s;
    font-size: 12px;
  }
  
  /* Styles for the label when it's in the default position */
  .formControlLabelRole {
    position: absolute;
    top: 5px;
    left: 14px;
    background-color: #fff;
    padding: 1px 3px;
    pointer-events: none;
    transition: top 0.2s, font-size 0.2s;
    font-size: 16px;
  }
  
  /* Styles for the select input */
  .formControlSelectRole {
    margin-top: 1px;
    height: auto;
  }
  

  /* input field */

  .input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
    box-shadow: inset 1px 1px 0 50px #ffffff;
}
.form-control {
    border: 1px solid #c4c5d6 !important;
}


.input-group {
    position: relative;
    margin-bottom: 1rem;
    margin-top: 1.2px;
}


.floating_input_field:-moz-placeholder-shown ~ .floating_label_field {
    position: absolute;
    top: 9.3px;
    font-size: 13.5px !important;
    pointer-events: none;
    padding-top: 0;
    font-family: var(--roboto) !important;
    font-weight: normal !important;
    padding-left: 8px !important;
    letter-spacing: 0.3px;
    color: rgb(87, 81, 81) ;
}

.floating_input_field:-ms-input-placeholder ~ .floating_label_field {
    position: absolute;
    top: 9.3px;
    font-size: 13.5px !important;
    pointer-events: none;
    padding-top: 0;
    font-family: var(--roboto) !important;
    font-weight: normal !important;
    padding-left: 8px !important;
    letter-spacing: 0.3px;
}

.floating_input_field:placeholder-shown ~ .floating_label_field,
.floating_label_field {
    position: absolute;
    top: 9.3px;
    font-size: 13.5px !important;
    pointer-events: none;
    padding-top: 0;
    font-family: var(--roboto) !important;
    font-weight: normal !important;
    padding-left: 8px !important;
    letter-spacing: 0.3px;
}

.floating_input_field:not(placeholder-shown) + .floating_label_field,
.floating_input_field:focus + .floating_label_field {
    position: absolute;
    top: -17px;
    -webkit-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
    left: 6px;
    color: #7d7d7d !important;
    font-family: var(--roboto) !important;
    color: rgb(5, 5, 5) !important;
    font-size: 11px !important;
    pointer-events: none !important;
    background: white !important;
    font-weight: normal !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    letter-spacing: 0;
    z-index: 999;
}

.floating_input_field:-moz-placeholder-shown + .error + .floating_label_field {
    position: absolute;
    top: 9.3px;
    font-size: 13.5px !important;
    pointer-events: none;
    padding-top: 0;
    font-family: var(--roboto) !important;
    font-weight: normal !important;
    padding-left: 8px !important;
    letter-spacing: 0.3px;
}

.floating_input_field:-ms-input-placeholder + .error + .floating_label_field {
    position: absolute;
    top: 9.3px;
    font-size: 13.5px !important;
    pointer-events: none;
    padding-top: 0;
    font-family: var(--roboto) !important;
    font-weight: normal !important;
    padding-left: 8px !important;
    letter-spacing: 0.3px;
}

.floating_input_field:placeholder-shown + .error + .floating_label_field {
    position: absolute;
    top: 21px;
    font-size: 13.5px !important;
    pointer-events: none;
    padding-top: 0;
    font-family: var(--roboto) !important;
    font-weight: normal !important;
    padding-left: 8px !important;
    letter-spacing: 0.3px;
}

.floating_input_field:not(placeholder-shown) + .error + .floating_label_field,
.floating_input_field:focus + .floating_label_field {
    position: absolute;
    top: -11px;
    -webkit-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
    left: 6px;
    color: #3a3939 !important;
    font-family: var(--roboto) !important;
    color: rgb(77, 75, 75) !important;
    font-size: 11px !important;
    pointer-events: none !important;
    background: white !important;
    font-weight: normal !important;
    padding-left: 3px !important;
    padding-right: 3px !important;
    letter-spacing: 0;
    z-index: 999;
}

.number_label {
    left: 43px;
    color: #7d7d7d;
}

.input_style {
    display: block;
    width: 97%;
    height: calc(1.3rem + 0.75rem + 2px);
    padding:5px !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5;
    color: #101111 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #C4C4C4 !important;
    outline: none !important;
    -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    border-radius: 4px !important;
}

.input_style.select_country {
    padding: 0.375rem 0.75rem 0.475rem 0.75rem;
}

.placeholder-style::placeholder {
    color: #ACB1C6;
    padding-left:5px;
    
}
