.menu {
  z-index: 1;
  height: 100vh;
}
.loginBodyTop {
  border-radius: 0px;
  filter: blur(5px);
  text-align: center;
  margin-right: auto;
  margin-left: 153px;
  filter: blur(0px);
  height: auto;
}

.loginBodyBottom {
  width: 100%;
  height: auto;
  filter: blur(0px);
}

.button:hover {
  background-color: aliceblue;
  color: #141e3c;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: none;
  align-items: center;
}

.terms {
  color: "#FF1B6F";
}
