/* styles.css */
.hrTable {
  border-collapse: separate;
  border: 1px solid #ececec;
  padding: 0;
}

.hrTable th,
.hrTable td {
  padding: 4px;
}

.hrTableCell {
  background-color: #fafafa;
  color: #9499a4;
  font-family: "Akatab", sans-serif;
  font-size: -16px;
  font-weight: 500;
  height: 20px;
}

.hrTableEachCell {
  height: 18px;
}

.hrHeaderText {
  font-size: 13px;
}

.hrTableRow > * {
  border: none;
}

.tableRow {
  height: 8px;
}

.hrCustomIcon {
  font-size: 16px;
}
