
.userInfo{
    width: 100%;
}

.userInfo .regular400{
    font-weight: 400;
}

.userInfo .medium500{
    font-weight: 500;
}

.userInfo .semiBold600{
    font-weight: 600;
}

.userInfo .bold700{
    font-weight: 700;
}

.userInfo p{
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.font-size-16{
    font-size: 14px;
    margin: 0;
}

.userInfo h6{
    font-size: 18px;
    margin: 0;
}

.userInfo h5{
    font-size: 20px;
    margin: 0;
    color: #000;
}

.userInfo h4{
    font-size: 22px;
    margin: 0;
    color: #000;
    text-align: center;
}

.userInfo p{
    color: #848484;
    font-size: 14px;
    font-weight: 400;
}

.userInfo a{
    text-decoration: none;
}

.userInfo  .com_Dis{
    display: flex;
    grid-gap: 16px;
}

.userInfo .nameEdit{
    display: flex;
    align-items: center;
    grid-gap: 15px;
    margin-top: 4px;
}

.userInfo .nameEdit a{
    flex-shrink: 0 !important;
}
.userInfo .date{
    letter-spacing: 1px;
    color: #05050F !important;
    margin-top: 12px;
}

.userInfo .btnWrapper{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    /* width: 100%; */
    margin-top: 20px;
}

.userInfo .btnWrapper .primBtn{
    background: #141E3C;
    padding: 10px 10px;
    width: 50%;
    color: #fff;
    border-radius: 8px ;
    border: 2px solid #141E3C;
    text-decoration: none;
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    transition: ease 0.3s;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.userInfo .btnWrapper .primBtn:hover{
    background: #fff;
    color: #141E3C;
}

.userInfo .btnWrapper .secoBtn{
    background: #fff;
    color: #141E3C;
    width: 60%;
}

.userInfo .btnWrapper .secoBtn:hover{
    background: #141E3C;
    color: #fff;
}

.userInfo .userInfoC{
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    padding: 16px;
    height: 100%;
}

.userInfo .userInfoC .profilePhoto{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background: #ECECEC;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    overflow: hidden;
    margin-top: 35px;
}

.disColCard{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.disColCard ul{
    margin: 0;
    padding: 0;
}

.disColCard ul li{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    width: 100%;
    grid-gap: 10px;
    font-size: 14px;
    color: #010101;
    margin-bottom: 8px;
}

.disColCard ul.type02 li{
    margin-bottom: 20px;
}

.disColCard ul.type03 li{
    margin-bottom: 15px;
}


.disColCard ul li:last-child{
    margin-bottom: 0 !important;
}

.disColCard .userInfoC{
    border-color: #48D1AC;
    border-radius: 9px;
}

.disColCard .userInfoC h6{
    color: #FE0B7A;
}

.userInfo .w-70p{
    width: 70%;
}

.userInfo .w-65p{
    width: 65%;
}

.userInfo .w-60p{
    width: 60%;
}

.userInfo .w-55p{
    width: 55%;
}

.userInfo .w-50p {
    width: 50%;
}

.userInfo .w-45p{
    width: 45%;
}

.userInfo .w-40p{
    width: 40%;
}

.userInfo .w-35p{
    width: 35%;
}

.userInfo .w-30p{
    width: 30%;
}

.userInfo  .lightColor{
    color: #848484;
}

.userInfo  .lightColor a{
    color: #141E3C;
}

.userInfo  .lightColor a:hover{
    color: #010101;
}

.disColCard ul.type02 li a{
    color: #007AFF;
    text-decoration: underline;
}

.userInfo .userInfoC.disColCard .com_Dis{
    grid-gap: 5px;
}

.userInfo .userInfoC.disColCard .com_Dis .userInfoC{
    padding:10px 6px;
}

@media (max-width: 1599.98px){
    .userInfo h6{
        font-size: 17px;
    }
    .disColCard ul li{
        font-size: 13px;
    }
}

@media (max-width: 1499.98px){
    .userInfo h6{
        font-size: 16px;
    }
}

@media (max-width: 1399.98px){
    .userInfo .com_Dis.com_DisWrapper{
        /*flex-direction: column;*/
        flex-wrap: wrap;
    }
    .userInfo .com_Dis.com_DisWrapper .w-55p.com_Dis{
        width: 100%;
        flex-shrink: 0 !important;
    }
    .userInfo .com_Dis.com_DisWrapper .w-45p.com_Dis{
        width: 100%;
        flex-shrink: 0 !important;
    }

    .userInfo .com_Dis.com_DisWrapper .w-55p.com_Dis .userInfoC.w-45p.disColCard, .userInfo .com_Dis.com_DisWrapper .w-55p.com_Dis .userInfoC.w-55p.disColCard{
        width: 50%;
    }

    .userInfo .com_Dis.com_DisWrapper .w-45p.com_Dis .userInfoC.w-55p.disColCard, .userInfo .com_Dis.com_DisWrapper .w-45p.com_Dis .userInfoC.w-45p.disColCard{
        width: 50%;
    }
}

@media (max-width: 991.98px){
    .com_Dis.com_DisWrapper .w-55p.com_Dis .userInfoC.w-55p.disColCard .w-100.com_Dis.mt-auto{
        /*flex-wrap: wrap;*/
        flex-direction: column;
    }

    .com_Dis.com_DisWrapper .w-55p.com_Dis .userInfoC.w-55p.disColCard .w-100.com_Dis.mt-auto .w-50p.userInfoC{
        width: 100%;
    }
}