.tableView
 {
  border-collapse: collapse;
}

.tabelTd {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-right: auto;
  text-align: start;
  color: rgb(133, 128, 128);

}

.tableHeader {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  margin-right: auto;
  text-align: start;
  font-weight: 500;
}
