/* styles.css */
.masterTable {
    border-collapse: separate;
    border: 1px solid #ececec;
    padding: 0;
    }
    
    .masterTable th,
    .masterTable td {
      padding: 4px;
    }
    
    .masterTableCell {
      background-color: #fafafa;
      color: #9499a4;
      font-family: 'Akatab', sans-serif;
      font-size: -16px;
      font-weight: 500;
      height: 20px;
    }
    
    .tableEachCell {
      height: 18px;
    }
    
    .headerText {
      font-size: 13px;
    }
    
    .tableRow > * {
      border: none;
    }
    
    .tableRow {
      height: 8px;
    }
    
    .customIcon {
      font-size: 16px;
    }
    