/* Styles for the form control wrapper */
.formControlWrapper {
    position: relative;
  }
  
  /* Styles for the label when it moves to the top */
  .formControlLabelMoved {
    position: absolute;
    top: -8px;
    left: 14px;
    background-color: #fff;
    padding: 2px 4px;
    pointer-events: none;
    transition: top 0.2s, font-size 0.2s;
    font-size: 12px;
  }
  
  /* Styles for the label when it's in the default position */
  .formControlLabel {
    position: absolute;
    top: 12px;
    left: 14px;
    background-color: #fff;
    padding: 2px 4px;
    pointer-events: none;
    transition: top 0.2s, font-size 0.2s;
    font-size: 16px;
  }
  
  /* Styles for the select input */
  .formControlSelect {
    margin-top: 8px;
  }
  